<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                perPageDropdown: [10, 25, 50, 100],
            }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'function_description'">
                    {{ cutString(props.row.function_description) }}
                </span>
                <span v-else-if="props.column.field == 'caption'">
                    {{ cutString(props.row.caption) }}
                </span>
                <span v-else-if="props.column.field == 'function_response'">
                    <div class="card mb-2" v-for="(item, index) in props.row.function_response" :key="index">
                        <div class="card-body">
                            <center>
                                <b>Document</b>
                                <br>
                                <a :href="item" target="_blank"> Open File </a>
                            </center>
                        </div>
                    </div>
                </span>
                <span v-else-if="props.column.field == 'action'">
                    <a v-on:click="editMedia(props.row.id)">
                        <a class="btn btn-primary text-white margin5">Detail</a>
                    </a>
                    <a v-on:click="deleteChatGPT(props.row.id)" class="btn btn-danger text-white margin5">
                        Hapus
                    </a>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>

export default {
    props: ['id'],
    data() {
        return {
            table: {
                total_records: 100,
                isLoading: true,
                columns: [
                    {
                        label: "Behavior",
                        field: "function_description",
                        filterOptions: {
                            enabled: true,
                        },
                        sortable: false,
                        width: "30%",
                    },
                    {
                        label: "Caption",
                        field: "caption",
                        filterOptions: {
                            enabled: true,
                        },
                        sortable: false,
                        width: "30%",
                    },
                    {
                        label: "File",
                        field: "function_response",
                        filterOptions: {
                            enabled: true,
                        },
                        sortable: false,
                        width: "30%",
                    },
                    {
                        label: "Action",
                        field: "action",
                        sortable: false,
                        width: "20%",
                    },
                ],
                rows: [],
                serverParams: {
                    columnFilters: {},
                    sort: [
                        {
                            field: "",
                            type: "",
                        },
                    ],
                    page: 1,
                    limit: 10,
                },
            },
        };
    },
    mounted() {
        this.fn = () => {
            this.loadItems();
        }
        this.$root.$on("refresh_table", this.fn);
        this.loadItems();
    },
    beforeDestroy() {
        this.$root.$off("refresh_table", this.fn);
    },
    methods: {
        cutString(string) {
            if (string == null || string == "") return ""

            if (string.length > 40) {
                string = string.slice(0, 40) + "..."
            }

            return string
        },
        async deleteChatGPT(id) {
            try {
                let response = await this.$store.dispatch("chatgpt_delete_media", { id: this.id, media_id: id });
                this.loadItems();
                this.$toast.success(response.data.message);
            } catch (err) {
                this.$toast.error("Server error");
            }
        },
        updateParams(newProps) {
            this.table.serverParams = Object.assign(
                {},
                this.table.serverParams,
                newProps
            );
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ limit: params.currentPerPage });
            this.loadItems();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [
                    {
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    },
                ],
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        async loadItems() {
            this.table.isLoading = true
            try {
                let response = await this.getFromServer(this.table.serverParams);
                this.table.total_records = response.data.data.total_records;
                this.table.rows = response.data.data.chatgpt;

                //   for (let i = 0; i < this.table.rows.length; i++) {
                //     this.table.rows[i].count_device = JSON.parse(this.table.rows[i].device).length ?? 0
                //   }
            } catch (err) {
                this.$toast.error("Too many request");
            }
            this.table.isLoading = false
        },
        async getFromServer(param) {
            param.chatgpt_id = this.id
            return await this.$store.dispatch("chatgpt_media_list", param);
        },
        async editMedia(id) {
            this.$root.$emit("toggle_modal", "edit_media")
            this.$root.$emit("edit_media", id)
        },
    },
};
</script>

<style scoped>
.margin5 {
    margin: 5px;
}
</style>