<template>
    <div>
        <form v-on:submit.prevent="updateOngkir">
            <div class="row">
                <div class="col-md-12">
                    <input name="hide" class="checkbox" type="checkbox" id="check-3" v-model="form.enable_ai_ongkir">
                    <label for="check-3">
                        <span class="checkbox-text" style="margin-left: 5px;">Aktifkan AI Cek Ongkir</span>
                    </label>
                    <br>
                    <label>Berat Produk (gram)</label>
                    <input type="number" class="form-control" v-model="form.product_weight" required />
                    <br />
                    <label>Asal Pengiriman</label>
                    <multiselect v-model="form.origin_id" tag-placeholder="Pilih Asal Pengiriman"
                        placeholder="Pilih Asal Pengiriman" label="name" track-by="id" :options="locations"
                        @search-change="getLocation" :loading="form.isLoading">
                    </multiselect>

                    <br>
                    <label>Kurir</label>
                    <multiselect v-model="selectedCourier" tag-placeholder="Pilih Kurir" placeholder="Pilih Kurir"
                        label="name" track-by="id" :options="courier" :loading="form.isLoading" :multiple="true">
                    </multiselect>
                </div>
            </div>
            <br>
            <div class="button-group d-flex pt-25">
                <button type="submit"
                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                    Simpan
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    props: ['id', 'form'],
    components: {
        Multiselect
    },
    data() {
        return {
            device: [],
            // form: {
            //     enable_ai_ongkir: false,
            //     berat_produk: 0,
            //     origin: null,
            // },
            locations: [],
            selectedDevice: null,
            courier: [
                {
                    "id": "jne",
                    "name": "JNE"
                },
                {
                    "id": "sicepat",
                    "name": "Si Cepat"
                },
                {
                    "id": "ide",
                    "name": "ID Express"
                },
                {
                    "id": "sap",
                    "name": "SAP"
                },
                {
                    "id": "jnt",
                    "name": "J&T"
                },
                {
                    "id": "ninja",
                    "name": "Ninja"
                },
                {
                    "id": "tiki",
                    "name": "TIKI"
                },
                {
                    "id": "lion",
                    "name": "Lion Parcel"
                },
                {
                    "id": "anteraja",
                    "name": "Anteraja"
                },
                {
                    "id": "pos",
                    "name": "POS Indonesia"
                },
                {
                    "id": "ncs",
                    "name": "NCS"
                },
                {
                    "id": "rex",
                    "name": "REX"
                },
                {
                    "id": "rpx",
                    "name": "RPX"
                },
                {
                    "id": "sentral",
                    "name": "Sentral Cargo"
                },
                {
                    "id": "star",
                    "name": "Star Cargo"
                },
                {
                    "id": "wahana",
                    "name": "Wahana"
                },
                {
                    "id": "dse",
                    "name": "DSE"
                }
            ],
            selectedCourier: [],
            // form: {
            //     device: [],
            //     name: "",
            //     api_key: "",
            //     models: "gpt-4o-mini",
            //     enable_memory: false,
            //     max_token_chat: 128,
            //     max_token_number: 1024,
            //     max_token_day: 102400,
            //     temperature: 0.5,
            //     top_p: 0.5,
            //     disable_during_office_hour: false,
            //     fallback_message: "",
            //     reenable_ai_duration: 15,
            //     custom_prompt: "",
            // },
        };
    },
    mounted() {
        // this.form.daily_max_token_number = this.form.daily_max_token_number
        // this.form.daily_max_token_global = this.form.daily_max_token_global
        // this.form.enable_memory = this.form.enable_memory == 1 ? true : false
        // this.form.disable_during_office_hour = this.form.disable_during_office_hour == 1 ? true : false

        // this.getDevice()
        if (this.form.origin_id == 0) {
            this.loadLocation("all")
        } else {
            this.loadLocation("all", this.form.origin_id)
        }

        if (this.form.courier != null) {
            this.selectedCourier = this.courier.filter(x => this.form.courier.includes(x.id))
        }
    },
    methods: {
        async getLocation(param) {
            //throttle watch this function
            this.debouncedGetLocation(param)
            // let res = await this.$store.dispatch("location_list_all", param);
            // this.locations = res.data.data.locations
        },
        debouncedGetLocation(param) {
            clearTimeout(this.debounceTimer)
            this.debounceTimer = setTimeout(() => {
                this.loadLocation(param)
            }, 300)
        },
        async loadLocation(param, ro_subdistrict_id = null) {
            if (param == null || param == "") return;
            let res = await this.$store.dispatch("location_search", {
                search: param == "all" ? "" : param,
                ro_subdistrict_id: ro_subdistrict_id
            });

            this.locations = res.data.data.map(x => ({
                id: x.ro_subdistrict_id,
                name: x.display,
            }));

            if (ro_subdistrict_id != null) {
                this.form.origin_id = this.locations.find(x => x.id == ro_subdistrict_id)
            }
        },
        async getDevice(param) {
            let res = await this.$store.dispatch("device_list_all", param);
            this.device = res.data.data.devices

            let d = this.device.find(x => x.id == this.form.device_id)
            this.selectedDevice = d
        },
        async updateOngkir() {
            try {
                // Salin form agar mudah dimanipulasi
                let request = {
                    id: this.id,
                    enable_ai_ongkir: this.form.enable_ai_ongkir ? 1 : 0,
                    product_weight: parseInt(this.form.product_weight),
                    origin_id: parseInt(this.form?.origin_id?.id) || 0,
                    courier: this.selectedCourier.map(x => x.id).join(":")
                };

                // Kirim request melalui Vuex action
                let response = await this.$store.dispatch('chatgpt_update_ongkir', request);

                // Tampilkan pesan sukses
                this.$toast.success(response.data.message);

                // Kembalikan form.device ke nilai awal
                //   this.form.device = old_device;

                // Jika diperlukan, aktifkan event berikut
                // this.$root.$emit("toggle_modal", "new_bot");
                // this.$root.$emit("refresh_table", true);
            } catch (err) {
                // Tangani error dan tampilkan pesan
                const errorMessage = err.response?.data?.message || 'Something went wrong';
                this.$toast.error(errorMessage);
            }
        }
    },
};
</script>
