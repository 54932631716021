<template>
    <div>
      <form v-on:submit.prevent="addChatGPT">
        <div class="row">
            <div class="col-md-12">
                <label>Nama Bot</label>
                <input type="text" class="form-control" v-model="form.name" autofocus required />
                <br />
                <label>AI Model</label>
                <select v-model="form.models" class="form-control" selected>
                    <option value="gpt-4o-mini" selected>[ChatGPT] gpt-4o-mini (Termurah)</option>
                    <option value="gpt-4o">[ChatGPT] gpt-4o</option>
                    <option value="gpt-4">[ChatGPT] gpt-4</option>
                    <option value="gpt-4-turbo">[ChatGPT] gpt-4-turbo</option>
                    <option value="gemini-2.0-flash">[Gemini] gemini-2.0-flash</option>
                    <!-- <option value="deepseek-chat">[Deepseek] deepseek-chat</option>
                    <option value="grok-2-latest">[Grok] grok-2-latest</option> -->
                </select>
                <br>
                <label for="">API KEY</label>
                <textarea v-model="form.api_key" class="form-control" rows="5"></textarea>
            </div>
            <div class="col-md-6" style="margin-top:10px">
                <label>Max Token / Chat <b>(Isi -1 untuk unlimited token)</b></label>
                <input type="number" placeholder="contoh : 128" class="form-control" v-model="form.max_token_chat" autofocus required />
                <br />
                <label>Max Token / Nomor <b>(Isi -1 untuk unlimited token)</b></label>
                <input type="number" placeholder="contoh : 1024" class="form-control" v-model="form.daily_max_token_number" autofocus required />
                <br />
                <label>Max Token / Hari <b>(Isi -1 untuk unlimited token)</b></label>
                <input type="number" placeholder="contoh : 100000" class="form-control" v-model="form.daily_max_token_global" autofocus required />
                <br />
                <label>Temperature (0.1 - 1)</label>
                <input type="number" placeholder="0.5" min="0" max="1" step="0.1" class="form-control" v-model="form.temperature" autofocus required />
                <i>*Makin tinggi, jawaban makin bervariasi</i>
                <br>
                <br />
                <label>Top P (0.1 - 1)</label>
                <input type="number" placeholder="0.5" min="0" max="1" step="0.1" class="form-control" v-model="form.top_p" autofocus required />
                <i>*Makin tinggi, jawaban makin panjang</i>
            </div>
            <div class="col-md-6"  style="margin-top:10px">
                <label for="">Reenable Ai Duration (menit)</label>
                <input type="number" placeholder="" class="form-control" v-model="form.reenable_ai_duration" autofocus required />
                <i>*Waktu percakapan kembali di Respon AI, setelah user chat dengan cs asli / jika spam terdeteksi</i>
                <br>
                <br>
                <!-- <input name="hide" class="checkbox" type="checkbox" id="check-1" v-model="form.disable_during_office_hour">
                <label for="check-1">
                    <span class="checkbox-text" style="margin-left: 5px;">Disable During Office Hour</span>
                </label>
                <br> -->
                <input name="hide" class="checkbox" type="checkbox" id="check-2" v-model="form.enable_memory">
                <label for="check-2">
                    <span class="checkbox-text" style="margin-left: 5px;">Enable Memory</span>
                </label>
                <br>
                <i>*ChatGPT akan mengingat percakapan 24 jam terakhir (billing lebih mahal)</i>
                <br>
            </div>
        </div>
        <br>
        <div class="button-group d-flex pt-25">
            <button type="submit" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
              Simpan
            </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
    //   import Multiselect from 'vue-multiselect'

  export default {
    props: ['form', 'id'],
    components: {
        },
    data() {
      return {
        device: [],
        selectedDevice: null,
        // form: {
        //     device: [],
        //     name: "",
        //     api_key: "",
        //     models: "gpt-4o-mini",
        //     enable_memory: false,
        //     max_token_chat: 128,
        //     max_token_number: 1024,
        //     max_token_day: 102400,
        //     temperature: 0.5,
        //     top_p: 0.5,
        //     disable_during_office_hour: false,
        //     fallback_message: "",
        //     reenable_ai_duration: 15,
        //     custom_prompt: "",
        // },
      };
    },
    mounted() {
        // this.form.daily_max_token_number = this.form.daily_max_token_number
        // this.form.daily_max_token_global = this.form.daily_max_token_global
        this.form.enable_memory = this.form.enable_memory == 1 ? true : false
        this.form.disable_during_office_hour = this.form.disable_during_office_hour == 1 ? true : false
 
        this.getDevice()
    },
    methods: {
        async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices

                let d = this.device.find(x => x.id == this.form.device_id)
                this.selectedDevice = d     
            },
            async addChatGPT() {
              try {
                  // Salin form agar mudah dimanipulasi
                  let request = { ...this.form };
                //   let old_device = this.form.device;

                  // Modifikasi request dengan data yang diperlukan
                  request.id = this.id;
                  request.device_id = this.selectedDevice?.id || null; // Pastikan ada fallback jika device.id tidak ada
                  request.enable_memory = request.enable_memory ? 1 : 0;
                  request.disable_during_office_hour = request.disable_during_office_hour ? 1 : 0;

                  // Pastikan parsing data aman
                  request.max_token_chat = parseInt(request.max_token_chat) || 0;
                  request.daily_max_token_number = parseInt(request.daily_max_token_number) || 0;
                  request.daily_max_token_global = parseInt(request.daily_max_token_global) || 0;
                  request.reenable_ai_duration = parseInt(request.reenable_ai_duration) || 0;
                  request.temperature = parseFloat(request.temperature) || 0.0;
                  request.top_p = parseFloat(request.top_p) || 0.0;

                  // Kirim request melalui Vuex action
                  let response = await this.$store.dispatch('chatgpt_update', request);

                  // Tampilkan pesan sukses
                  this.$toast.success(response.data.message);

                  // Kembalikan form.device ke nilai awal
                //   this.form.device = old_device;

                  // Jika diperlukan, aktifkan event berikut
                  // this.$root.$emit("toggle_modal", "new_bot");
                  // this.$root.$emit("refresh_table", true);
              } catch (err) {
                  // Tangani error dan tampilkan pesan
                  const errorMessage = err.response?.data?.message || 'Something went wrong';
                  this.$toast.error(errorMessage);
              }
        }
    },
  };
  </script>
  
