<template>
    <div>
        <center>
            <h4>Tambah / Edit Media</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addMedia" enctype="multipart/form-data">
            <label> Behavior </label>
            <textarea v-model="form.behavior" rows="5" class="form-control"
                placeholder="Contoh : Jika user meminta mengirimkan katalog produk"></textarea>
            <br>
            <label>Caption</label>
            <textarea v-model="form.caption" rows="3" class="form-control"
                placeholder="Contoh : Berikut adalah katalog produk kami"></textarea>
            <br>
            <button class="btn btn-primary btn-sm mb-2" v-on:click="addFile">Tambah Media</button>
            <br>
            <div v-for="(item, index) in media" :key="index" class="card mb-2">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
                        <button class="" style="width: 25px; height: 25px; padding: 1px;font-size: 10px;background-color: red;color: white;border: none;border-radius: 25%;"
                            v-on:click="removeFile(index)">X</button>
                    </div>
                    <input ref="file" type="file" @change="onFileChange(index, $event)" class="form-control"
                        v-if="!item.oldFile" required>
                    <input ref="file" type="file" @change="onFileChange(index, $event)" class="form-control" v-else>
                    <i v-if="item.oldFile">*Kosongkan jika tidak ingin mengubah file</i>
                    <div v-if="item.oldFile">
                        <br>
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <center>
                                    <b>Document</b>
                                    <br>
                                    <a :href="item.oldFile" target="_blank"> Open File </a>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
            <input ref="file" type="file" @change="onFileChange" class="form-control" v-if="!form.oldFile" required>
            <input ref="file" type="file" @change="onFileChange" class="form-control" v-else>
            <i v-if="form.oldFile">*Kosongkan jika tidak ingin mengubah file</i>

            <div v-if="form.oldFile">
                <br>
                <label> File Lama </label>
                <div class="card">
                    <div class="card-body">
                        <center>
                            <b>Document</b>
                            <br>
                            <a :href="form.oldFile" target="_blank"> Open File </a>
                        </center>
                    </div>
                </div>
            </div> -->
            <!-- <label for="">Pesan Registrasi</label>
            <CustomTextArea v-model="form.message"></CustomTextArea>
            <i>Anda bisa memanggil variabel menggunakan [] (Bracket)</i>
            <br>
            <i>Variabel Tersedia : {{ available_variable }}</i>
            <br> -->
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>

export default {
    props: ['id', "media_id"],
    components: {
    },
    data() {
        return {
            form: {
                behavior: "",
                caption: "",
                oldFile: "",
                file: "",
            },
            media: []
        };
    },
    beforeDestroy() {
        this.$root.$off("toggle_modal", this.fnToggleModal);
    },
    mounted() {
        this.fnToggleModal = (param) => {
            this.toggleModal(param);
        }

        this.$root.$on("toggle_modal", this.fnToggleModal);

        if (this.media_id) {
            this.getMedia()
        }
    },
    methods: {
        addFile() {
            this.media.push({
                oldFile: "",
                file: "",
            })
        },
        removeFile(index) {
            this.media.splice(index, 1);
        },
        toggleModal(param) {
            this.$modal.toggle(param);
        },
        onFileChange(index, e) {
            const selectedFile = e.target.files[0];
            this.media[index].file = selectedFile;

            console.log(this.media)
        },
        async getMedia() {
            let res = await this.$store.dispatch("chatgpt_media_get", { id: this.id, media_id: this.media_id });
            let r = res.data.data.chatgpt

            this.form.behavior = r.function_description
            this.form.caption = r.caption

            for (let i = 0; i < r.function_response.length; i++) {
                this.media.push({
                    oldFile: r.function_response[i],
                    file: "",
                })
            }
        },
        async addMedia() {
            try {
                let fileURL = []
                //upload file 
                if (this.media.length > 0) {
                    for (let i = 0; i < this.media.length; i++) {
                        if (this.media[i].file) {
                            const formData = new FormData();
                            formData.append('file', this.media[i].file)
                            let response = await this.$store.dispatch('cdn_upload', formData)
                            // console.log("response", response)
                            fileURL.push(response.data.data.url)
                        } else {
                            fileURL.push(this.media[i].oldFile)
                        }
                    }
                }

                let d = {
                    "behavior": this.form.behavior,
                    "file": fileURL,
                    "caption": this.form.caption
                }

                let create_media
                // let create media 
                if (this.media_id) {
                    create_media = await this.$store.dispatch('chatgpt_media_update', { id: this.id, media_id: this.media_id, data: d })
                    this.$root.$emit("toggle_modal", "edit_media")
                } else {
                    create_media = await this.$store.dispatch('chatgpt_media_create', { id: this.id, data: d })
                    this.$root.$emit("toggle_modal", "add_media")
                }
                this.$toast.success(create_media.data.message)
                this.$root.$emit("refresh_table", true)
            } catch (err) {
                this.$toast.error(err.response?.data?.message || err);
            }
        }
    },
};
</script>

<style scoped>
table {
    border: 1px solid;
}
</style>