<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Detail ChatGPT</h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <!-- <a v-on:click="toggleModal('new_campaign')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-upload fs-16"></i>
                                Import Anggota
                            </a> -->
                            <a v-on:click="toggleModal('import')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-upload fs-16"></i>
                                Import Knowledge
                            </a>
                            <!-- <a v-on:click="trainDataset()" class="btn px-15 btn-secondary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-robot fs-16"></i>
                                Training Knowledge
                            </a> -->
                            <download-csv ref="exportMember" :data="campaignMembers" :name="campaignName"
                                style="display: none"></download-csv>
                            <!-- <a v-on:click="toggleModal('new_campaign')" class="btn px-15 btn-success text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-message fs-16"></i>
                                Broadcast
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 col-sm-12">
                    <div class="col-12">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h5>Kuota Tersisa :</h5>
                            <b>{{ formatAngka(chatgpt.quota) }}</b> Respon
                            <hr>
                            <button class="btn btn-primary btn-block btn-sm" v-on:click="toggleModal('topup')">TopUp
                                Kuota Respon</button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h4>Device List</h4>
                            <hr>
                            <ChatGPTDevice :id="edit_id" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h5> Setting Jam Kerja </h5>
                            <br>
                            <form v-on:submit.prevent="saveJamKerja">
                                <div v-if="chatgpt.jam_kerja_is_active == '1'" class="jam_kerja_container">
                                    <label for="">Pesan diluar jam kerja</label>
                                    <br>
                                    <label for="">Senin</label>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_buka"
                                                v-model="jam_kerja.senin.buka" placebolder="Buka" value="">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_tutup"
                                                v-model="jam_kerja.senin.tutup" placebolder="Tutup" value="">
                                        </div>
                                    </div>
                                    <br>
                                    <label for="">Selasa</label>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_buka"
                                                v-model="jam_kerja.selasa.buka" placebolder="Buka" value="">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_tutup"
                                                v-model="jam_kerja.selasa.tutup" placebolder="Tutup" value="">
                                        </div>
                                    </div>
                                    <br>
                                    <label for="">Rabu</label>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_buka"
                                                v-model="jam_kerja.rabu.buka" placebolder="Buka" value="">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_tutup"
                                                v-model="jam_kerja.rabu.tutup" placebolder="Tutup" value="">
                                        </div>
                                    </div>
                                    <br>
                                    <label for="">Kamis</label>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_buka"
                                                v-model="jam_kerja.kamis.buka" placebolder="Buka" value="">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_tutup"
                                                v-model="jam_kerja.kamis.tutup" placebolder="Tutup" value="">
                                        </div>
                                    </div>
                                    <br>
                                    <label for="">Jumat</label>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_buka"
                                                v-model="jam_kerja.jumat.buka" placebolder="Buka" value="">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_tutup"
                                                v-model="jam_kerja.jumat.tutup" placebolder="Tutup" value="">
                                        </div>
                                    </div>
                                    <br>
                                    <label for="">Sabtu</label>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_buka"
                                                v-model="jam_kerja.sabtu.buka" placebolder="Buka" value="">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_tutup"
                                                v-model="jam_kerja.sabtu.tutup" placebolder="Tutup" value="">
                                        </div>
                                    </div>
                                    <br>
                                    <label for="">Minggu</label>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_buka"
                                                v-model="jam_kerja.minggu.buka" placebolder="Buka" value="">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="time" class="form-control timepicker_tutup"
                                                v-model="jam_kerja.minggu.tutup" placebolder="Tutup" value="">
                                        </div>
                                    </div>
                                    <br>
                                    <button class="btn btn-success btn-block" type="submit">Simpan</button>
                                    <a class="btn btn-danger btn-block" v-on:click="setJamKerja(false)">Nonaktifkan</a>
                                </div>
                                <a v-else v-on:click="setJamKerja(true)" class="btn btn-success btn-block ">Aktifkan</a>
                            </form>
                        </div>
                    </div>

                </div>
                <div class="col-md-7 col-sm-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                                <div class="tab-wrapper">
                                    <div class="atbd-tab tab-horizontal">
                                        <ul class="nav nav-tabs vertical-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{ active: page.one }"
                                                    v-on:click="changePage('one')">Detail</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{ active: page.four }"
                                                    v-on:click="changePage('four')">Behaviour</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{ active: page.two }"
                                                    v-on:click="changePage('two')">Knowledge</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{ active: page.five }"
                                                    v-on:click="changePage('five')">Media </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{ active: page.six }"
                                                    v-on:click="changePage('six')">Cek Ongkir <span
                                                        class="badge badge-info"
                                                        style="font-size: 12px !important">BETA</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{ active: page.three }"
                                                    v-on:click="changePage('three')">History Message</a>
                                            </li>


                                        </ul>
                                        <div class="tab-content" v-if="!isLoading">
                                            <div class="tab-pane fade" :class="{ 'show active': page.one }" id="tab-v-1"
                                                role="tabpanel" aria-labelledby="tab-v-1-tab">
                                                <Edit :device="device" :id="edit_id" :form="chatgpt" />
                                            </div>
                                            <div class="tab-pane fade" :class="{ 'show active': page.four }"
                                                id="tab-v-1" role="tabpanel" aria-labelledby="tab-v-1-tab">
                                                <EditBehaviour :device="device" :id="edit_id" :form="chatgpt" />
                                            </div>
                                            <div class="tab-pane fade" :class="{ 'show active': page.two }" id="tab-v-2"
                                                role="tabpanel" aria-labelledby="tab-v-2-tab">
                                                <button v-on:click="toggleModal('add_dataset')"
                                                    class="btn btn-success">Tambah Knowledge</button>
                                                <br>
                                                <br>
                                                <ChatGPTDataset :id="edit_id" />
                                            </div>
                                            <div class="tab-pane fade" :class="{ 'show active': page.three }"
                                                id="tab-v-2" role="tabpanel" aria-labelledby="tab-v-2-tab">
                                                <ChatGPTHistory :id="edit_id" />
                                            </div>
                                            <div class="tab-pane fade" :class="{ 'show active': page.five }"
                                                id="tab-v-2" role="tabpanel" aria-labelledby="tab-v-2-tab">
                                                <button v-on:click="toggleModal('add_media')"
                                                    class="btn btn-success">Tambah
                                                    Media</button>
                                                <br>
                                                <br>
                                                <ChatGPTMedia :id="edit_id" />
                                            </div>
                                            <div class="tab-pane fade" :class="{ 'show active': page.six }" id="tab-v-2"
                                                role="tabpanel" aria-labelledby="tab-v-2-tab">
                                                <Ongkir :device="device" :id="edit_id" :form="chatgpt" />
                                            </div>
                                        </div>
                                        <div class="tab-content" v-else>
                                            <center>
                                                <div class="spinner-border spinner" role="status"></div>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <modal name="add_dataset" width="70%" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddDataset :id="edit_id"></AddDataset>
                </div>
            </modal>
            <modal name="edit_dataset" width="70%" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddDataset :id="edit_id" :dataset_id="dataset_id"></AddDataset>
                </div>
            </modal>
            <modal name="view_history" width="50%" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <ViewHistory :data="history_data"></ViewHistory>
                </div>
            </modal>
            <modal name="import" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <ImportDataset :id="edit_id" />
                </div>
            </modal>
            <modal name="add_device" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddDevice :id="edit_id" :device="device" />
                </div>
            </modal>
            <modal name="topup" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <Topup :id="edit_id" />
                </div>
            </modal>
            <modal name="add_media" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddMedia :id="edit_id"></AddMedia>
                </div>
            </modal>
            <modal name="edit_media" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddMedia :id="edit_id" :media_id="media_id"></AddMedia>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import Edit from "@/components/forms/chat_gpt/sharing/Edit.vue";
import EditBehaviour from "@/components/forms/chat_gpt/EditBehaviour.vue";
import ChatGPTDataset from "@/components/tables/ChatGPTDataset.vue";
import ChatGPTHistory from "@/components/tables/SharingChatGPTHistory.vue";
import ChatGPTMedia from "@/components/tables/ChatGPTMedia.vue";
import AddDataset from "@/components/forms/chat_gpt/AddDataset.vue";
import ViewHistory from "@/components/forms/chat_gpt/sharing/ViewHistory.vue";
import AddDevice from "@/components/forms/chat_gpt/AddDevice.vue";
import AddMedia from "@/components/forms/chat_gpt/AddMedia.vue";
import Topup from "@/components/forms/chat_gpt/Topup.vue";
import Ongkir from "@/components/forms/chat_gpt/Ongkir.vue";

import ImportDataset from "@/components/forms/chat_gpt/ImportDataset.vue";
import ChatGPTDevice from "@/components/tables/ChatGPTDevice.vue";

//import SendBlast from "@/components/forms/outgoing/SendBlast.vue";
//import moment from 'moment'

export default {
    components: {
        Edit,
        ChatGPTDataset,
        AddDataset,
        ChatGPTHistory,
        ImportDataset,
        EditBehaviour,
        ViewHistory,
        ChatGPTDevice,
        AddDevice,
        Topup,
        ChatGPTMedia,
        AddMedia,
        Ongkir
        //SendBlast
    },
    data() {
        return {
            edit_id: this.$route.query.id,
            page: {
                one: true,
                two: false,
                three: false,
                four: false,
                five: false,
                six: false,
                seven: false
            },
            isLoading: true,
            device: [],
            chatgpt: {},
            campaignMembers: [],
            campaignName: "",
            dataset_id: "",
            history_data: {},
            media_id: "",
            jam_kerja: {
                senin: {
                    buka: "00:00",
                    tutup: "00:00"
                },
                selasa: {
                    buka: "00:00",
                    tutup: "00:00"
                },
                rabu: {
                    buka: "00:00",
                    tutup: "00:00"
                },
                kamis: {
                    buka: "00:00",
                    tutup: "00:00"
                },
                jumat: {
                    buka: "00:00",
                    tutup: "00:00"
                },
                sabtu: {
                    buka: "00:00",
                    tutup: "00:00"
                },
                minggu: {
                    buka: "00:00",
                    tutup: "00:00"
                }
            },
        };
    },
    async beforeMount() {

    },
    beforeDestroy() {
        this.$root.$off("toggle_modal", this.fnToggleModal);
        this.$root.$off("edit_dataset", this.fnSetDatasetID);
        this.$root.$off("edit_media", this.fnSetMediaID);
        this.$root.$off("history_data", this.fnSetHistoryData);
        this.$root.$off("refresh_quota", this.getAllData);

    },
    mounted() {
        this.fnToggleModal = (param) => {
            this.toggleModal(param);
        }

        this.fnSetDatasetID = (param) => {
            this.dataset_id = param;
            // this.toggleModal("edit_dataset");
        }

        this.fnSetHistoryData = (param) => {
            this.history_data = param;
        }

        this.fnSetMediaID = (param) => {
            this.media_id = param;
        }


        this.$root.$on("toggle_modal", this.fnToggleModal);
        this.$root.$on("edit_dataset", this.fnSetDatasetID);
        this.$root.$on("history_data", this.fnSetHistoryData);
        this.$root.$on("refresh_quota", this.getAllData);
        this.$root.$on("edit_media", this.fnSetMediaID);

        this.getAllData()
    },
    methods: {
        async getAllData() {
            await this.getDevice({
                status: "all"
            }).then(data => {
                this.device = data
            })
            await this.getChatGptDetail({
                id: this.edit_id
            }).then(data => {
                this.chatgpt = data
                if (data.jam_kerja) {
                    this.jam_kerja = JSON.parse(data.jam_kerja)
                }
            })
            this.isLoading = false
        },
        toggleModal(param) {
            this.$modal.toggle(param);
        },
        async getDevice(param) {
            let res = await this.$store.dispatch("device_list_all", param);
            return res.data.data.devices
        },
        async getChatGptDetail(param) {
            let res = await this.$store.dispatch("chatgpt_detail", param);
            return res.data.data.chatgpt
        },
        changePage(page) {
            this.page.one = false
            this.page.two = false
            this.page.three = false
            this.page.four = false
            this.page.five = false
            this.page.six = false
            this.page.seven = false

            if (page == "one") this.page.one = true
            if (page == "two") this.page.two = true
            if (page == "three") this.page.three = true
            if (page == "four") this.page.four = true
            if (page == "five") this.page.five = true
            if (page == "six") this.page.six = true
            if (page == "seven") this.page.seven = true
        },
        async trainDataset() {
            let res = await this.$store.dispatch("chatgpt_train", { id: this.edit_id });
            this.$toast.success(res.data.message)
        },
        formatNumber(number) {
            if (number.charAt(0) == "0") {
                number = "62" + number.substring(1)
            }
            return number
        },
        parseVar(inputString) {
            let parts = inputString.split('#');
            parts.shift();

            let nonEmptyParts = parts.filter(part => part.trim() !== '');

            let capitalizedParts = nonEmptyParts.map(part => {
                return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
            });

            return capitalizedParts;
        },
        toDateTime(unixTimestamp) {
            const dateObject = new Date(unixTimestamp);

            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Tambah 1 karena bulan dimulai dari 0
            const day = String(dateObject.getDate()).padStart(2, '0');

            const formattedDate = `${year}/${month}/${day}`;

            return formattedDate
        },
        formatAngka(angka) {
            const strAngka = angka.toString();

            // Gunakan regex untuk menambahkan titik setiap tiga digit
            return strAngka.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        setJamKerja(param) {
            try {
                this.chatgpt.jam_kerja_is_active = param

                let data = {
                    id: this.edit_id,
                    jam_kerja_is_active: param ? 1 : 0,
                    jam_kerja: this.jam_kerja
                }
                this.$store.dispatch("chatgpt_update_jam_kerja", data)

                this.$toast.success(`Berhasil ${param ? "mengaktifkan" : "me-nonaktifkan"} jam kerja`)
            } catch (error) {
                this.$toast.error("Gagal mengubah jam kerja")
            }
        },
        saveJamKerja() {
            try {
                let data = {
                    id: this.edit_id,
                    jam_kerja_is_active: this.chatgpt.jam_kerja_is_active ? 1 : 0,
                    jam_kerja: this.jam_kerja
                }
                this.$store.dispatch("chatgpt_update_jam_kerja", data)

                this.$toast.success("Berhasil mengubah jam kerja")

            } catch (error) {
                this.$toast.error("Gagal mengubah jam kerja")
            }
        }
    },
};
</script>

<style scoped>
.nav-link {
    cursor: pointer !important;
}

.spinner {
    height: 1rem !important;
    width: 1rem !important;
}
</style>